import React from 'react';
import {Container, Grid, Segment,} from 'semantic-ui-react';
import styles from "./aboutUs.module.css";
import {graphql, StaticQuery} from "gatsby";
import Img from "../utils/Img";
import cx from "classnames";

const Board = () => (
    <StaticQuery query={boardItemsQuery}
                 render={
                     data => {
                         const Items = data.allMarkdownRemark.edges.map(i =>
                             <Segment>
                                 <Grid stackable columns={2}>
                                     <Grid.Row stackable columns={2}>
                                         <Grid.Column width={6}>
                                             <Img fluid={i.node.frontmatter.image.childImageSharp.fluid}/>
                                         </Grid.Column>
                                         <Grid.Column width={10}>
                                             <p className={styles.name}>{i.node.frontmatter.title}</p>
                                             <Container className={cx(styles.details, styles.container)}
                                                        dangerouslySetInnerHTML={{__html: i.node.html}}></Container>
                                         </Grid.Column>
                                     </Grid.Row>
                                 </Grid>
                             </Segment>
                         );
                         return (<div className={styles.main}>{Items}</div>)
                     }
                 }
    />
);

export default Board;

const boardItemsQuery = graphql`
query boardItemsQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {
            type: { eq: "person" }
            kind: { eq: "board" }
         }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
            html
            frontmatter {
                title
                image {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
            }
        }
      }
    }
}
`;